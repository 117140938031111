import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';
import { IS_PUBLIC } from 'src/app/core/interceptor/auth.interceptor';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CrendentialsService {

    private version = environment.apiVersion;
    private readonly CONTEXT_PUBLIC = new HttpContext().set(IS_PUBLIC, true);

    constructor(private readonly http: HttpClient) { }

    // {{api_security}}/v1/credentials/{{user_id}}
    updateCredentials(type, model: { id: string, confirmPassword: string; password: string; }): Observable<any> {
        const header = new HttpHeaders({ 'x-flow-type': type });
        return this.http.put(`security/${this.version}/credentials/${model.id}`, { ...model, id: null }, { headers: header });
    }


    // post {{api_security}}/v1/credentials/recovery
    // {
    //     "username": "neriogalam@gmail.com"
    // }
    postRecoveryService = (email: string): Promise<any> => {
        const model = { username: email };
        try {
            const header = new HttpHeaders({ 'X-Source': 'app-web', 'X-Device': '' });
            return lastValueFrom(this.http.post('security/v1/credentials/recovery', model, { headers: header, context: this.CONTEXT_PUBLIC }));
        } catch (error) {
            console.log(error, 'error');
            return Promise.resolve(error);
        }
    }

    // {{api_security}}/v1/credentials/recovery/validate
    // {
    //     "username": "a47ob3q3t3pCjAiglFvLFcDwSkQkoB399eoPY25jelVEq/qukLhXD0yB+Kn2qQJIDwTUStrCWd3IwnF/tOlHR7fsLBpeolCd1QoI8811Tp0V5ZIWuZOxc6n8xyOLdj3mD4usmx7cQRbxc2J/b5ehjpTDdQtPDIRma/65JoznwT6rAg2LLdU4/fOg4PSXLEI5Z3Lnp2S0kzDa9WG2qT4eA6gj8X2jCLh94exErI5MwnqkVitQDBe7o8IUYpa3PxrQnAW+MGOzSe+exW33C94iRTLw0T84r3NuvjKkAYfsORoPWC1QHIvwy64cTWCQXo3z6EPiWh00k6hcU67u+MyDRXrjj+bhlX42C9/I0hLFbA0groPyrbzuAHl8AOUs/gJOgwZDMSI9tsvcoAEdgjrFOSMLgJH7CgOyT2geBGEJMnk5J2uaS+sw/E8Wx1JvsVuIhGqi8EM2jLvk1+a3avqGgUQcvo5h2T1u0c9NOPAUmUSvBdFjtNsXUrmYz8Xt3SJlKkRJjiguI58zo/pQBBeOGaEGZlXVMFipSTbV1N9f21cuHKas9e1VNOVuXQsc4Y6UqDZYjyiGRZ6zXSt6PvEulv9ZfbHeYhgdmIEFYqnqOHBXs58ThPxNcvrm9aDt6Z7PwaPUH7IGdlkPiErZ8E3OcbfLb1drMPXddgCAT+fV9ZM=",
    //     "code": "JUeAfz+bggf77mH/8FRevTjGnNAFvvMcLhwLfc9HfMqgGjfJMxOxwH6BqV9MP1Tfgu9di09F2wkgHzwkys0WOj6zFxJW+m7x7yYr4l8qX6sW8FjqqREPNLBt1P/umWMNJPzuIdldkFDSJmB6KxDFeImFNKOCV7YaPOLkrQE1bhxYkC1y0lONbm70vZlH6fyOVbOxIdIu0C5O6AkFY0r2JojL3r4L9Y4nXHKrMaeAV1Wu66n5HH7uU2ZBfHVlIt5ZXbEbgIedEetGNv0u6ZLsNDaZt0+xZznvk2pQ6aKpS2aikjUkpLpt65AvhxZGlsiIxEut14qAyf8lP8+dV51EXo+ktLmW1a72PrMRc6B4KcUCkkEaQMYO4cY3MrVgZQShAJiKvocRk6HryWgqqxjQ8a6WfIWIaYm/F8ZtLYQRzcWAmwPVV3zB1+muLj1xYcFUHYWysMDMHVESXF4lsfXLF65le7FaxvDJcebgOQP2CGmv/1b/20IqUWgIhFd5wZFr20YOZyPOFNwwVQI2APW/gtEVoE0+qsxg8VE18uD3ZuHyaJcyzH+3fHNJ+H/9OxIRF4inTDG0aV+a1zKWbbBVNtKgxNQnCQNUhbXTYKC8J2zkJMZn9e5sMRPgUDKop4ATcJOEB+yQ3nhiSgi/iacbGw5xsmWhhrDTaBYXGfj+7YI="
    // }
    putRecoveryValidateService = (model: { username: string, code: string }): Promise<any> => {
        try {
            const header = new HttpHeaders({ 'X-Source': 'app-web', 'X-Device': '' });
            return lastValueFrom(this.http.put('security/v1/credentials/recovery/validate', model, { headers: header, context: this.CONTEXT_PUBLIC }));
        } catch (error) {
            console.log(error, 'error');
            return Promise.resolve(error);
        }
    }


    //{{api_security}}/v1/credentials/recovery/update
    // {
    //     "code": "a47ob3q3t3pCjAiglFvLFcDwSkQkoB399eoPY25jelVEq/qukLhXD0yB+Kn2qQJIDwTUStrCWd3IwnF/tOlHR7fsLBpeolCd1QoI8811Tp0V5ZIWuZOxc6n8xyOLdj3mD4usmx7cQRbxc2J/b5ehjpTDdQtPDIRma/65JoznwT6rAg2LLdU4/fOg4PSXLEI5Z3Lnp2S0kzDa9WG2qT4eA6gj8X2jCLh94exErI5MwnqkVitQDBe7o8IUYpa3PxrQnAW+MGOzSe+exW33C94iRTLw0T84r3NuvjKkAYfsORoPWC1QHIvwy64cTWCQXo3z6EPiWh00k6hcU67u+MyDRXrjj+bhlX42C9/I0hLFbA0groPyrbzuAHl8AOUs/gJOgwZDMSI9tsvcoAEdgjrFOSMLgJH7CgOyT2geBGEJMnk5J2uaS+sw/E8Wx1JvsVuIhGqi8EM2jLvk1+a3avqGgUQcvo5h2T1u0c9NOPAUmUSvBdFjtNsXUrmYz8Xt3SJlKkRJjiguI58zo/pQBBeOGaEGZlXVMFipSTbV1N9f21cuHKas9e1VNOVuXQsc4Y6UqDZYjyiGRZ6zXSt6PvEulv9ZfbHeYhgdmIEFYqnqOHBXs58ThPxNcvrm9aDt6Z7PwaPUH7IGdlkPiErZ8E3OcbfLb1drMPXddgCAT+fV9ZM=",
    //     "password": "DiIV4a8KNkIESebemuLsqrnQA+MQljHI4emhiqNKbCL029rdLDEn8SxEdcNln8BmYZHuSUhRAIvAJpXXLoJOdDUtdSrb4zXivoNbRPsPBx7PMSd7ojhUbhFiU3rGGNObbcbcoQQrQ4Ek+9ZIdf6vmjWCe11Ucj7GxPktiwOgamO769IdzTDvLGXF4f9Kx9/7KQi9/BCLP7uOHu4IrevqdyO+YwpNCYA6oyNT+fDAbXJXV5FgHQiME2nLudQ7byN2xWha4DFuv3QxMKGTTPuqf+PuzxahSiTKGOdDa6RmUDn1fydzK0cqcRHrkoDs7Q/i+D2yjzZz2TxIEwtgLT2nA1XocA+0yaINkCta50T4ovx5A2dXVbi1bCtyBXu8XohA0ir7nv6IdqCvYSomfGvOvnuEVFNalG0HlwQuI27rr2tyhRbHre1SRmBNT95XtXT8jJNcDkdm8MTNqMxY9/NprUFIMsYTR0i5ccbY838FMCPahWXaK9qteDjb/uUwbkhq8rBmxhuS3BN6xcM9RqBgMcVU/yiE5VcUIS9WVTjj+3dvamO+/hQlHs9TQGGghIouKkDnaDZ8gP2qOJ+a+c3eB1yWwHBJhQNhz97fM0cUQ7w07lIR0hxNpp9nzJTe+OPqvu31DziFluo3dBzPCNdfNFVJgq15NsLIhel44zUw6Fw=",
    //     "confirmPassword": "LQZgsqCVAK2ZfSE6sAdIil1/MrG1RpuYuqh+CFlfx1kpbsmpn5jXeP12KoZ1A52PWcziehYlBHmS4RC+dt1gzdSUwaTUl+/iq3QOoccrVSiHViLYyTSkVuT8dScUQ57JVsejSCBW/HPr5dg5IQBAEbPqlOflWGZxSsjdUb9XwCgJIIIlAbir1IvJw9bE+Zssv1I4lzpDpc776n5iHi/ZsrZusIj61UnQO/9ZtCZ+l8+/NvJ4TBTwEmwN5XX6fuTlzkXV29rrekdVWXBt+ei6gYqGfFkWjrZJnDP1O4i9JuufgzbOnftuJqXaarvlp2yfcFcgBKi0HMm/ucWckTaI1KM/PZfRGUz2h3OAwWWuf16PgFyvaKwYgCJXLD5yrqHjO9gqqlFCOJxGBDYLkF9tE12inDz4g5JlG8QBFcSm6V4xFuJgDgnG5XqyD6SUVWk5fhPUaj9FlswmAq2zNJAW3DDVwngbzp6+vfKPjquXflFE5Tsl+at74ZCctfATl9dPhXZf0Qe1NruAm9bFIHUFQrj/+AtVW593MauriQHXrJHV5ahcLb2SRkqoke2WsAPiyKo7O/LpvRYaHoV4QiI1HfVdjgYixOV+S+/KYDoKCduo1nDpa3eXIS434bTsoDhttXcLOD2DGimuFEdJeG1AonwyK0+Hcccd6GDzMhq4Dmk="
    // }
    putRecoveryUpdateService = (model: { code: string, password: string, confirmPassword: string }): Promise<any> => {
        try {
            const header = new HttpHeaders({ 'X-Source': 'app-web', 'X-Device': '' });
            return lastValueFrom(this.http.put('security/v1/credentials/recovery/update', model, { headers: header, context: this.CONTEXT_PUBLIC }));
        } catch (error) {
            console.log(error, 'error');
            return Promise.resolve(error);
        }
    }

}
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule, importProvidersFrom } from '@angular/core';
import { AgGridModule } from '@ag-grid-community/angular';
import { RouterOutlet } from '@angular/router';

// in app.module.ts
import { NgxDropzoneModule } from 'ngx-dropzone';

//auth mdules
import { JwtModule } from '@auth0/angular-jwt';
//modules
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { SharedModule } from './shared/shared.module';
import { UsersModule } from './modules/users/users.module';
import { SettingModule } from './modules/setting/setting.module';
import { AuthModule } from './modules/auth/auth.module';
import { AssociatesModule } from './modules/associates/associate.module';
import { NgOtpInputModule } from 'ng-otp-input';

import { ToastrModule } from 'ngx-toastr';

//interceptor
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors } from '@angular/common/http';
import { basePathInterceptor } from './core/interceptor/base-path.interceptor';

//services
import { authInterceptor } from './core/interceptor/auth.interceptor';

//components
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ROOT_REDUCERS } from './core/provider/app.state';

//stores
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TypeClubEffects } from './core/provider/ngxstore/effect/type-club.effect';
import { RefreshTokenService } from './core/services/api/auth/refresh-token.service';
import { progressBarInterceptor } from './core/interceptor/progress-bar.interceptor';
import { ProgressBarComponent } from './shared/components/progress-bar/progress-bar.component';
import { CustomDatepickerI18n, I18n } from './core/provider/moment/datepicker-i18n';
import { NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { httpErrorInterceptor } from './core/interceptor/http-error.interceptor';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    AppRoutingModule,
    DashboardModule,
    SettingModule,
    AuthModule,
    SharedModule,
    AssociatesModule,
    UsersModule,
    AgGridModule,
    RouterOutlet,
    ProgressBarComponent,
    // stores
    StoreModule.forRoot(ROOT_REDUCERS),
    StoreDevtoolsModule.instrument({ name: 'TEST' }),
    EffectsModule.forRoot([TypeClubEffects]),//TODO: <-----
    NgxDropzoneModule,
    DatePipe,
    NgOtpInputModule
  ],
  providers: [
    I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
    // provideRouter(routes), provideClientHydration(),
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: initializeAppDynamicRouting,
    //   multi: true,
    //   deps: [Router, AuthService],
    // },

    {
      provide: APP_INITIALIZER,
      useFactory: initializerFactory,
      multi: true,
      deps: [RefreshTokenService]
    },
    // { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    importProvidersFrom([
      JwtModule.forRoot({
        config: {
          tokenGetter: () => sessionStorage.getItem('token')
        }
      })
    ]),
    provideHttpClient(
      withInterceptors([authInterceptor, basePathInterceptor, progressBarInterceptor, httpErrorInterceptor]),
    )
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }



export function initializerFactory(refresh: RefreshTokenService) {
  return () => refresh.refreshToken();
}

// routes dynamic
// export function initializeAppDynamicRouting(router: Router,
//   authService: AuthService): () => Promise<void> {
//   return () =>
//     new Promise((resolve) => {
//       const refresh_token = localStorage.getItem('token');
//       if (refresh_token && authService.isAuthenticated()) {
//         router.resetConfig([...routes]);
//       }
//       else {
//         // router.resetConfig([ ...routes]);
//         router.resetConfig([
//           ...routes,
//           {
//             path: 'auth/login',
//             component: LoginComponent,
//           },
//         ]);
//       }
//       // authService.login()
//       // setTimeout(() => {
//       //   resolve();
//       // }, 50);
//     });
// }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImagesComponent } from './components/images/images.component';
import { SwitchStatusComponent } from './components/switch-status/switch-status.component';
import { FormsModule } from '@angular/forms';
import { MapsComponent } from './components/maps/maps.component';
import { GoogleMapsModule } from "@angular/google-maps";


@NgModule({
  declarations: [ImagesComponent, SwitchStatusComponent, MapsComponent],
  imports: [
    CommonModule,
    FormsModule,
    GoogleMapsModule
  ],
  exports: [ImagesComponent, SwitchStatusComponent, MapsComponent]
})
export class SharedComponentsModule { }

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CrendentialsService } from 'src/app/core/services/api/auth/credentials.service';

@Component({
  selector: 'app-recover-email',
  templateUrl: './recover-email.component.html',
  styleUrl: './recover-email.component.scss'
})
export class RecoverEmailComponent implements OnInit {

  //forms
  public form: FormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
  });

  public submitted: boolean;
  public sedding: boolean;

  constructor(private readonly fb: FormBuilder,
    private crendentialsService: CrendentialsService,
    private router: Router) {
  }

  ngOnInit(): void {
    // throw new Error('Method not implemented.');
  }

  onFormSubmitted = async () => {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.submitted = false;
    try {
      this.sedding = true;
      const { email } = this.form.value;
      const response = await this.crendentialsService.postRecoveryService(email.trim());
      if (response && response.status === "Notified!") {
        this.router.navigate(["/auth/recovery-code", email]);
      }
      this.sedding = false;
    } catch (error) {
      console.log(error, 'error data');
      this.sedding = false;
    }
  }

  get c() {
    return this.form.controls;
  }

   handleResend = () => {
    // setResent(true);
    this.onFormSubmitted();
    let lastTimerCount = 30;
    let interval = setInterval(() => {
      // setSeconds((lastTimerCount) => {
        if (lastTimerCount == 0) {
          lastTimerCount = 30;
          // return lastTimerCount;
        } else {
          lastTimerCount <= 1 && clearInterval(interval);
          lastTimerCount = -1;
          // return lastTimerCount - 1;
        }
      // });
    }, 1000);
    return () => clearInterval(interval);
  };

}

import { Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { catchError, throwError } from 'rxjs';

import { AuthService } from 'src/app/core/services/api/auth/auth.service';
import { RegisterService } from 'src/app/core/services/api/auth/register.service';
import { UpdateTokenService } from 'src/app/core/services/api/auth/update-tokens.service';
import { ConfirmedValidator, regexPassword } from 'src/app/core/services/local/confirm-password';
import { HashedValueService } from 'src/app/core/services/local/hashed-value.service';

import { ILogin, ILoginSuccess, IRegister } from 'src/app/interface/auth';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public active = 1;
  public type: string = 'password';
  //forms
  public loginForm: FormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', Validators.required]
  });

  public registerForm: FormGroup = this.fb.group({
    firstname: ['', [Validators.required]],
    lastname: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.pattern(regexPassword)]],
    confirmPassword: ['', [Validators.required, Validators.pattern(regexPassword)]],
  }, {
    validator: ConfirmedValidator('password', 'confirmPassword'),
  });

  // status submit login
  public submittedLogin: boolean;
  public seddingLogin: boolean;

  // status submit register
  public submittedRegister: boolean;
  public seddingRegister: boolean;

  constructor(private readonly fb: FormBuilder,
    private readonly authSvc: AuthService,
    private readonly router: Router,
    private readonly updateToken: UpdateTokenService,
    private readonly regSvc: RegisterService,
    private readonly destroyRef: DestroyRef,
    private readonly hashed: HashedValueService
  ) { }

  ngOnInit() {
    sessionStorage.clear();
  }

  toggleShow() {
    this.type = this.type === 'text' ? 'password' : 'text';
}


  get c() {
    return this.loginForm.controls;
  }


  // // "email": "admin@admin.com",
  // // "name": "Administrator"
  onLoginFormSubmitted() {
    sessionStorage.clear();
    this.submittedLogin = true;
    if (!this.loginForm.valid) {
      return;
    }
    this.submittedLogin = false;
    const { password } = this.loginForm.value;
    const model = { ...this.loginForm.value, password: this.hashed.hashSHA1(password) };
    this.seddingLogin = true;
    this.authSvc.login(model as ILogin).subscribe({
      next: (data: any) => {
        const loginSuccessData = data as ILoginSuccess;
        this.updateToken.setTokens(loginSuccessData);
        setTimeout(() => {
          this.router.navigate(['/dashboard']);
        }, 1000);
        
        this.seddingLogin = false;
      },
      error: (e) => {
        this.seddingLogin = false;
      },
      complete: () => console.info('complete')
    });
  }


  get r() {
    return this.registerForm.controls;
  }


  onRegisterFormSubmitted() {
    sessionStorage.clear();
    this.submittedRegister = true;
    if (!this.registerForm.valid) {
      return;
    }
    this.submittedRegister = false;
    const { password, confirmPassword } = this.registerForm.value;
    const model = {
      ...this.registerForm.value,
      password: this.hashed.hashRSA_SHA1(password),
      confirmPassword: this.hashed.hashRSA_SHA1(confirmPassword)
    };
    this.seddingRegister = true;
    this.regSvc.register(model as IRegister)
      .pipe(
        catchError((error: any) => {
          this.seddingRegister = false;
          return throwError('Something went wrong');
        }),
        takeUntilDestroyed(this.destroyRef)
      ).subscribe((data: any) => {
        const loginSuccessData = data as ILoginSuccess;
        this.updateToken.setTokens(loginSuccessData);
        setTimeout(() => {
          this.router.navigate(['/dashboard']);
        }, 1000);
        this.seddingRegister = false;
      });
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CrendentialsService } from 'src/app/core/services/api/auth/credentials.service';
import { RSAHelperService } from 'src/app/core/services/local/rsahelper.service';

@Component({
  selector: 'app-recover-code',
  templateUrl: './recover-code.component.html',
  styleUrl: './recover-code.component.scss'
})
export class RecoverCodeComponent implements OnInit {

  //forms
  public form: FormGroup = this.fb.group({
    code: ['', [Validators.required, Validators.maxLength(6), Validators.minLength(6)]],
    email: ['', [Validators.required, Validators.email]]
  });

  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    showError: true,
    placeholder: '*',
    inputStyles: {
      'width': '45px',
      'height': '45px',
    }
  };


  public submitted: boolean;
  public sedding: boolean;

  constructor(private readonly fb: FormBuilder,
    private crendentialsService: CrendentialsService,
    private route: ActivatedRoute,
    private readonly toast: ToastrService,
    private readonly _RSAHelperService: RSAHelperService,
    private router: Router) {
    this.form.setValue({
      code: '',
      email: (route.snapshot.params.email).trim()
    });
  }

  ngOnInit(): void {

  }

  onFormSubmitted = async () => {
    this.submitted = true;
    if (this.form.invalid) {
      if (this.form.controls.email.errors) {
        this.toast.error("Email no es válido!!", "Error");
      }
      if (this.form.controls.code.errors) {
        this.toast.error("Código no es válido!!", "Error");
      }
      return;
    }
    this.submitted = false;
    try {
      const { code, email } = this.form.value;
      const model = {
        username: this._RSAHelperService.encryptWithPublicKey(email),
        code: this._RSAHelperService.encryptWithPublicKey(code.trim())
      };
      this.sedding = true;
      const response = await this.crendentialsService.putRecoveryValidateService(model);
      // console.log(response, 'response data');
      if (response.message === "OTP Valided") {
        sessionStorage.setItem('code', response.code);
        this.router.navigate(["/auth/recovery-password"]);
      } else {
        this.toast.error("Código no validado!", "Error");
      }
      this.sedding = false;
    } catch (error) {
      console.log(error, 'error data');
      this.sedding = false;
    }
  }

}

<div class="page-wrapper">
  <div class="authentication-box">
    <div class="container">
      <div class="row">
        <div class="col-md-5 p-0 card-left">
          <app-svg-img></app-svg-img>
        </div>
        <div class="col-md-7 p-0 card-right">
          <div class="card tab2-card">
            <div class="card-header">
              <h5 style="color: gray; text-transform: uppercase; text-align: center">Cambiar contraseña Email</h5>
            </div>
            <div class="card-body">
              <form
                [formGroup]="form"
                class="form-horizontal auth-form"
                novalidate="">
                <div class="form-group">
                  <input
                    formControlName="email"
                    name="login[email]"
                    type="email"
                    [ngClass]="{
                      'is-invalid': submitted && c.email.errors
                    }"
                    class="form-control"
                    placeholder="Email"
                    id="exampleInputEmail1" />
                  <div
                    *ngIf="c.email.invalid && (c.email.dirty || c.email.touched || submitted)"
                    class="alert">
                    <div *ngIf="c.email.errors?.required">Email es requerido</div>
                    <div *ngIf="c.email.errors?.email">Email incorrecto</div>
                  </div>
                </div>
                <div class="form-button">
                  <button
                    [disabled]="sedding"
                    (click)="onFormSubmitted()"
                    class="btn btn-primary"
                    type="button">
                    Enviar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

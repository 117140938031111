import { Component, DestroyRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CrendentialsService } from 'src/app/core/services/api/auth/credentials.service';
import { UpdateTokenService } from 'src/app/core/services/api/auth/update-tokens.service';
import { ConfirmedValidator, regexPassword } from 'src/app/core/services/local/confirm-password';
import { HashedValueService } from 'src/app/core/services/local/hashed-value.service';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrl: './recover-password.component.scss'
})
export class RecoverPasswordComponent implements OnInit {

  //forms
  public form: FormGroup = this.fb.group({
    password: ['', [Validators.required, Validators.pattern(regexPassword)]],
    confirmPassword: ['', [Validators.required, Validators.pattern(regexPassword)]]
  }, {
    validator: ConfirmedValidator('password', 'confirmPassword'),
  });
  submitted: boolean;
  sedding: boolean;
  private code: string;

  constructor(private readonly fb: FormBuilder,
    private readonly toast: ToastrService,
    private readonly crendentialsService: CrendentialsService,
    private readonly hashed: HashedValueService,
    private readonly updateToken: UpdateTokenService,
    private readonly router: Router
  ) { }

  ngOnInit(): void {
    this.code = sessionStorage.getItem('code');
  }

  onFormSubmitted = async () => {
    this.submitted = true;
    if (this.form.invalid) {
      this.toast.error("Datos ingresados inválidos");
      return;
    }
    this.submitted = false;
    try {
      const { password, confirmPassword } = this.form.value;
      const model = {
        code: this.code,
        password: this.hashed.hashRSA_SHA1(password),
        confirmPassword: this.hashed.hashRSA_SHA1(confirmPassword)
      };
      this.sedding = true;
      const response = await this.crendentialsService.putRecoveryUpdateService(model);
      this.updateToken.setTokens(response);
      setTimeout(() => {
        this.router.navigate(['/dashboard']);
        sessionStorage.removeItem('code');
      }, 1000);
      this.sedding = false;
    } catch (error) {
      console.log(error, 'error data');
      sessionStorage.removeItem('code');
    }
  }

  get c() {
    return this.form.controls;
  }

}

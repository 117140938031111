<div class="page-wrapper">
  <div class="authentication-box">
    <div class="container">
      <div class="row">
        <div class="col-md-5 p-0 card-left">
          <app-svg-img></app-svg-img>
        </div>
        <div class="col-md-7 p-0 card-right">
          <div class="card tab2-card">
            <div class="card-header">
              <h5 style="color: gray; text-transform: uppercase; text-align: center">Código de validación</h5>
            </div>
            <div class="card-body">
              <form *ngIf="form" [formGroup]="form">
                <div class="o-c">
                  <ng-otp-input
                    [config]="config"
                    formControlName="code">
                  </ng-otp-input>
                </div>
                <div class="form-button mt-3">
                  <button
                    [disabled]="sedding"
                    (click)="onFormSubmitted()"
                    class="btn btn-primary"
                    type="button">
                    Enviar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
